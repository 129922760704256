module.exports = {
  siteTitle: 'Niaga Tools', // <title>
  manifestName: 'newage',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-newage/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  socialLinks: [
    {
      icon: 'fa-whatsapp',
      name: 'Whatsapp',
      url: 'https://api.whatsapp.com/',
    },
  ],
};
